import React from 'react';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

const ExcelExport = ({ data, fileName }) => {
    const exportToExcel = () => {
        //Convert JSON data to worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);

        //Get the range of the worksheet
        const range = XLSX.utils.decode_range(worksheet['!ref']);

        //Specify the column index to format as numbers
        const numericColumnNames = ['balance', 'amount'];

        //Find the column index by name
        const header = Object.keys(data[0]);
        const numericColumnIndices = numericColumnNames.map(name => header.indexOf(name));

        //Loop through each cell in the specified column
        numericColumnIndices.forEach((colIndex) => {
            for (let row = range.s.r + 1; row <= range.e.r; row++) {
                const cellRef = XLSX.utils.encode_cell({r: row, c:colIndex});
                const cell = worksheet[cellRef];

                //Check if the cell exists and contains a number
                if (cell && !isNaN(cell.v)) {
                    cell.t = 'n'; //set the cell type to number
                }
            }
        });

        //Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        //Generate excel file buffer
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        //Create a blob and trigger download
        const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
        saveAs(blob, `${fileName}.xlsx`);
    };

    return (
        <button onClick={exportToExcel}>Export to Excel</button>
    );
}

export default ExcelExport;